<template>
     <footer class="footer grid lg:grid-cols-2 gap-2 p-4 bg__blue mt-10 flex items-center">
          <section>
            &copy; All Rights Reserved. Kiz Adventures GH.
          </section>
          <section class="flex justify-end">
            <a href="https://facebook.com/KizAdventures" target="_blank"><i class="fab fa-2x fa-facebook p-2"></i></a>
            <a href="https://instagram.com/KizAdventuresgh" target="_blank"><i class="fab fa-2x fa-instagram p-2"></i></a>
            <i class="fab fa-2x fa-twitter p-2"></i>
            <a href="https://www.youtube.com/channel/UCAW04kITRul2Gxa5JJp5veQ" target="_blank"><i class="fab fa-2x fa-youtube p-2"></i></a>
          </section>
        </footer>
</template>

<script>
  export default{
    name: 'Footer',
  }
</script>

<style>

</style>
