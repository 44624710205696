<template>
  <Layout>
    <NavBar />
     <header class="banner grid lg:grid-cols-2 py-5">
            <section class="lg:p-4">
                <h1 class="lg:text-5xl p-5 font-extrabold">An Amazing Community of kizomba dancers based in Ghana</h1>
            </section>
            <section class="flex justify-center p-2">
                <img src="@/assets/img/serena.jpeg" alt="serena smiling" class="rounded-lg " h>
            </section>
        </header>
        <!-- /banner -->
        <!-- about us -->
        <section class="w-full mt-10">
            <h2 class="font-bold section__title text-3xl text-center my-5">About Us</h2>
            <section class="w-full px-4 lg:w-2/4 mx-auto lg:text-2xl">
                <p>
                    We're a group of kizomba lovers on a mission to create a better community regardless of kizomba group affiliation as well as promote good vibes and events.
                </p>
            </section>
            <section class="p-10 lg:px-32 mt-10 w-full grid lg:grid-cols-3 gap-4">
                <section class="border rounded border-gray-200 border-2 bg-gray-200">
                    <img src="@/assets/img/serena-two.jpeg" alt="" class=" rounded-lg">
                    <section class="my-2">
                        <h3 class="section__title--sub font-bold text-center text-4xl">Serena</h3>
                        <p class="mt-4 p-4">
                            At Kiz Adventures Gh, Serena wears many hats. Amongst them, are leading strategy, social media management and marketing , partners and dancers relations.
                        </p>
                    </section>
                </section>
                <section class=" border rounded border-gray-200 border-2 bg-gray-200">
                    <img src="@/assets/img/nana.png" alt="" class=" rounded-lg lg:h-72 w-full">
                    <section class="my-2">
                        <h3 class="section__title--sub font-bold text-center text-4xl">Nana Kayden</h3>
                        <p class="mt-4 p-4">
                            At Kiz Adventures Gh, Kayden runs operations and is in charge of multimedia and doubles as assistant strategist for Serena.
                        </p>
                    </section>
                </section>
                <section class=" border rounded border-gray-200 border-2 bg-gray-200">
                    <img src="@/assets/img/ramos.jpeg" alt="" class=" rounded-lg">
                    <section class="my-2">
                        <h3 class="section__title--sub font-bold text-center text-4xl">Kofi Ramos</h3>
                        <p class="mt-4 p-4">
                            At Kiz Adventures Gh, Ramos handle all things software and websites as well as talk strategy with the other team members.
                        </p>
                    </section>
                </section>
            </section>
        </section>
        <!-- /about is -->
        <!-- Partners -->
        <section class="w-full mt-10">
            <h2 class="font-bold section__title text-3xl text-center my-5">Partners</h2>
            <section class="w-full px-4 lg:w-2/4 mx-auto lg:text-2xl">
                <p class="text-center">
                    A few Collaboration are in pipeline.
                </p>
            </section>
        </section>
        <!-- /Partners -->


        <!-- Partners -->
        <section class="w-full mt-10">
            <h2 class="font-bold section__title text-3xl text-center my-5">Members</h2>
            <section class="w-full px-4 lg:w-2/4 mx-auto lg:text-2xl">
                <p class="text-center">
                    A few Kizomba dancers from the community.
                </p>

                <section class="grid grid-cols-3  lg:grid-cols-6 gap-2 mt-4">
                  <img src="@/assets/img/serena.jpeg"  class="rounded-full w-24 h-24" alt="picture of serena" title="serena">
                  <img src="@/assets/img/nana.png"  class="rounded-full h-24 w-24" alt="picture of nana" title="nana kayden">
                  <img src="@/assets/img/ramos.jpeg"  class="rounded-full h-24 w-24" alt="picture of ramos" title="ramos">
                  <img src="@/assets/img/sky-mary.jpeg"  class="rounded-full h-24 w-24" alt="picture of sky mary" title="sky">
                  <img src="@/assets/img/pearl.jpeg"  class="rounded-full h-24 w-24" alt="picture of pearl" title="pearl">

                </section>
            </section>
        </section>
        <!-- /Partners -->
        <Footer />
      </Layout>
</template>

<script>
import Layout from '@/components/TheLayout'
import NavBar from '@/components/TheHeader'
import Footer from '@/components/TheFooter'
  export default{
    name: 'Home',

    components:{
      Layout,
      NavBar,
      Footer,
    }

  };

</script>
