<template>
    <Home />
</template>

<script>
import Home from '@/pages/Home'


export default {
  name: 'App',
  components: {
    Home,
  },
}
</script>

<style>
@import 'assets/css/main.css';
@import 'assets/css/custom.css';

</style>
