<template>
    <nav class="lg:flex justify-between items-center lg:h-20 px-2 bg__blue">
            <span class="brand font-extrabold text-2xl px-4">Kiz Adventures</span>
            <ul class="nav__links flex lg:flex-row flex-col">
                <li class="px-2">Home</li>
                <li class="px-2">About Us</li>
                <li class="px-2"></li>
                <li class="px-2">Partners</li>
                <li class="px-2">Community</li>
            </ul>
        </nav>
</template>


<script>
export default{

  name:'NavBar',
}

</script>


<style>

</style>
